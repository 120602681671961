import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/InterDisplay-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/InterDisplay-Italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./fonts/InterDisplay-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./fonts/InterDisplay-BoldItalic.ttf\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/UnstyledButton.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Button.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Accordion.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/ActionIcon.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Anchor.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/AspectRatio.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Badge.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Burger.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Card.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Chip.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Container.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Grid.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Group.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Input.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Combobox.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Pill.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/PillsInput.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Popover.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/ScrollArea.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Flex.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Alert.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/CloseButton.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/List.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Timeline.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Notification.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Paper.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/SimpleGrid.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Stack.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Table.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Text.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/ThemeIcon.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/Title.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles/TypographyStylesProvider.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/images/og.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/MantineWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/src/app/PosthogProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/ShepherdAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ShepherdHeader/ShepherdHeaderClient.tsx");
